import React from 'react';
import Card from './Card';
// import { robots } from './robots'; 

const CardList = ({robots}) => {
  const cardArray = 'abcdefghijklmnopqrstuvwxyz0123456789'; // 36
  const cardArray1 = 'abcdefghi!@$'; //12 remove # as it makes pics smaller
  const cardArray2 = 'jklmnopqr^&*'; //12
  const cardArray3 = 'stuvwxyz()_+'; //12
  const cardArray4 = '0123456789=-'; //12
  //generate 10 random uint8 numbers
  // const array = new Uint8Array(1);// 1 number
  //generate 10 random uint16 numbers
  // const array16 = new Uint16Array(1); //1 number
  const ids=[];
 /* for (let r = 0; r < robots.length; r++){
   ids[r] = window.crypto.getRandomValues(array16) /3.9
                +window.crypto.getRandomValues(array)
                + cardArray1[Math.floor(Math.random() * 12)]
                +cardArray2[Math.floor(Math.random() * 12)]
                +cardArray3[Math.floor(Math.random() * 12)]
                + cardArray4[Math.floor(Math.random() * 12)]
                +cardArray[Math.floor(Math.random() * 36)]
                +cardArray[Math.floor(Math.random() * 36)];
                // console.log('inside id', ids[r]);
  }*/
  const getRandomInt=(min, max)=> {       
    // Create byte array and fill with 1 random number
    var byteArray = new Uint8Array(1);
    window.crypto.getRandomValues(byteArray);

    var range = max - min + 1;
    var max_range = 256;
    if (byteArray[0] >= Math.floor(max_range / range) * range)
        return getRandomInt(min, max);
    return min + (byteArray[0] % range);
}

for (let r = 0; r < robots.length; r++){
   ids[r] = getRandomInt(0, 255) 
          + cardArray1[Math.floor(Math.random() * 12)]
          +cardArray2[Math.floor(Math.random() * 12)]
          +cardArray3[Math.floor(Math.random() * 12)]
          + cardArray4[Math.floor(Math.random() * 12)]
          +cardArray[Math.floor(Math.random() * 36)]
          +cardArray[Math.floor(Math.random() * 36)];
                 // console.info('inside id', ids[r]);
  }

// window.crypto.getRandomValues(array);
  // var key = robot[3].id;
  // console.info('ids CardList',ids);
  return (
    <div>
      {
        robots.map((robot,i) => {
          return (
            <Card
              key = { i}
              ids = {
              // console.log('here',ids[i])
                 ids[i]
                 // '13914.358974358975209#'
              }
              // id = {id[Math.floor(Math.random()*9)]}
              // id= {Math.floor(Math.random() * 3333) /3.3}
              /*id= { window.crypto.getRandomValues(array16)[Math.floor(Math.random()*10)] /3.3
                +window.crypto.getRandomValues(array)[Math.floor(Math.random()*10)]
                + cardArray[Math.floor(Math.random() * 36)]
                +cardArray[Math.floor(Math.random() * 36)]
                +cardArray[Math.floor(Math.random() * 36)]
                + cardArray[Math.floor(Math.random() * 36)]
                +cardArray[Math.floor(Math.random() * 36)]
                +cardArray[Math.floor(Math.random() * 36)]}*/
            //    id = { id[Math.floor(Math.random() * 10)]}
              name = {robot.name } 
              email = {robot.email} 
            />
          );
        })
      }
    </div>
  );
}

export default CardList;