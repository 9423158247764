import React, { Component} from 'react';
import { connect } from 'react-redux';
import CardList from '../components/CardList';
import SearchBox from '../components/SearchBox';
// import { robots } from './robots'; // replaced by fetch request
import Scroll from '../components/Scroll';
import './App.css';

import { setSearchField, requestRobots } from '../actions.js';

const mapStateToProps = state => {
  return {
    searchField: state.searchRobots.searchField,
    robots: state.requestRobots.robots,
    isPending: state.requestRobots.isPending,
    error: state.requestRobots.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSearchChange: (event) => dispatch(setSearchField(event.target.value)),
     // same as requestRobots(dispatch) requestRobots needs to have dispatch method
    onRequestRobots: () => dispatch(requestRobots())
  }
}

class App extends Component {
  // no more state
  // constructor() {
  //   super()
  //   this.state = {
  //     robots: []
  //   }
  // }

  componentDidMount() {
    // this replaces constructor => state etc ..
    this.props.onRequestRobots();
  }

  // my function
  // onSearchChange = (e) => { // changed to props in redux
  //   // use setState instead of this.searchfield = xxx
  //   this.setState({ searchfield: e.target.value })
  //   // console.log(filteredBots);
  // }

  render() {
    const { searchField, onSearchChange, robots, isPending } = this.props;
    const filteredBots = robots.filter(r => {
      return r.name.toLowerCase().includes(searchField.toLowerCase());
    });
    // doesn't work
    // const length = this.state.robot.filter(r => this.state.robot.length === 0);
    return isPending ? 
       <h1 className='tc'>Loading</h1>
       : (
        <div className='tc'>
          <h1 className='f2' style={{"display": "inline", "marginLeft":"10em"}}>MonsterMania-v2</h1>
          <h1 className='f3' style={{display: "inline"}}> redux </h1>
          <h1 className='f6' style={{"display": "inline"}}> <a  href='https://fatchance.tech/monster' style={{"marginLeft" : "7em"}}> Click for v1</a></h1>
          <SearchBox change = {onSearchChange } />
          <Scroll>
            <CardList robots = { filteredBots } />
          </Scroll>  
        </div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);