import React from 'react';

const Card = ({name, email, ids}) => {
  // const  = props;
  // let id = 0;
        // console.info('in card.js', ids);

  return (
    <div className='tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5'>
      <img alt='robots' 
        src={`https://robohash.org/set_any/bgset_bg2/${ids}?size=350x350`} 
      />
      <div>
        <h2>{name}</h2>
        <p>{email}</p>
        {//console.info(ids)
        }
      </div> 
    </div>
  );
}

export default Card;